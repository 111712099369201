<template>
  <b-card-code>
    <div v-if="$can('create', 'Mark Distribution')" class="custom-search d-flex">
      <router-link :to="{ path: '/mark/distribution' }">
        <b-button variant="outline-primary">
          {{ $t('Add new') }}
        </b-button>
      </router-link>
    </div>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form method="post" action="javascript:void(0)" ref="form_add_or_update" class="repeater-form">
        <b-row>

          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Class') }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('class')" rules="required">
                <select v-model="classes_id" class="form-control" name="classes_id"
                  :state="errors.length > 0 ? false : null">
                  <option :value="null">Select one</option>
                  <option v-for="(cls, key) in classes" :key="key" :value="cls.id">{{ cls.name }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Version') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('version')"
                  rules="required"
              >
                <select v-model="version_id" class="form-control"
                        name="version_id"
                        :state="errors.length > 0 ? false:null">
                  <option :value="null">Select one</option>
                  <option v-for="(version, key) in versions" :key="key" :value="version.id">{{version.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Student group') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('student group')"
                  rules="required"
              >
                <select v-model="student_group_id" class="form-control"
                        name="student_group_id"
                        :state="errors.length > 0 ? false:null">
                  <option :value="null">Select one</option>
                  <option v-for="(sgrp, key) in student_groups" :key="key" :value="sgrp.id">{{sgrp.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Academic Year') }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('academic year')" rules="required">
                <select v-model="academic_year_id" class="form-control" name="academic_year_id"
                  :state="errors.length > 0 ? false : null">
                  <option :value="null">Select one</option>
                  <option v-for="(aYear, key) in academic_years" :key="key" :value="aYear.id">{{ aYear.year }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Exam type') }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('exam type')" rules="required">
                <select v-model="exam_type_id" class="form-control" name="exam_type_id"
                  :state="errors.length > 0 ? false : null">
                  <option :value="null">Select one</option>
                  <option v-for="(etype, key) in exm_types" :key="key" :value="etype.id">{{ etype.name }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>
        <!-- Row Loop -->
        <b-row v-for="(item, index) in filteredsubjectList" :id="item" :key="index" ref="row">
          <template v-if="subjectByID(item.subject_id) != '' && subjectByID(item.subject_id) != null">
            <!-- Mark from -->
            <b-col md="3">
              <b-form-group>
                <b-card-text>
                  <span>{{ $t('Subject Name') }} </span>
                </b-card-text>
                <validation-provider #default="{ errors }" :name="$t('subject name')" rules="required">
                  <b-form-input :value="subjectByID(item.subject_id)" readonly :state="errors.length > 0 ? false : null"
                    :placeholder="$t('subject name')" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Select Grade') }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('class')" rules="required">
                <select class="form-control"  :id="item.subject_id + '_grade'"
                  :state="errors.length > 0 ? false : null">
                  <option :value="null">Select Grade</option>
                  <option v-for="(grade, key) in grade_types" :key="key" :value="grade.id" :selected= "grade.id == item.grade_id">{{ grade.name }}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

            <b-col cols="12">
              <hr>
            </b-col>
            <b-row v-for="(inp, index2) in mk_distributions" :id="'ss' + inp.id" :key="index2" ref="row2"
              style="padding:15px">
              <template v-if="inp.subject_id === item.subject_id">
                <b-col md="2">
                  <b-form-group>
                    <b-card-text>
                      <span>{{ $t('Mark type') }} </span>
                    </b-card-text>
                    <validation-provider #default="{ errors }" :name="$t('mark type')" rules="required">
                      <b-form-input v-model.number="inp.name" readonly :state="errors.length > 0 ? false : null"
                        :placeholder="$t('mark type')" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group>
                    <b-card-text>
                      <span>{{ $t('Total mark') }} </span>
                    </b-card-text>
                    <validation-provider #default="{ errors }" :name="$t('total mark')" rules="required">
                      <b-form-input v-model.number="inp.total_mark" :state="errors.length > 0 ? false : null"
                        :placeholder="$t('total mark')" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group>
                    <b-card-text>
                      <span>{{ $t('Pass mark') }} </span>
                    </b-card-text>
                    <validation-provider #default="{ errors }" :name="$t('pass mark')" rules="required">
                      <b-form-input v-model.number="inp.pass_mark" :state="errors.length > 0 ? false : null"
                        :placeholder="$t('pass mark')" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group>
                    <b-card-text>
                      <span>{{ $t('To be added(TM×CM%)') }} </span>
                    </b-card-text>
                    <validation-provider #default="{ errors }" :name="$t('mark percent add')" rules="required">
                      <b-form-input v-model.number="inp.mark_percent_add" :state="errors.length > 0 ? false : null"
                        :placeholder="$t('mark percent add')" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="2" class="mt-2 pt-1">
                  <b-button v-if="$can('update', 'Mark Distribution')" variant="outline-primary"
                    @click="markUpdate(inp.id, inp)" type="button">
                    {{ $t('Update') }}
                  </b-button>
                </b-col>
              </template>
            </b-row>
          </template>
        </b-row>
      </b-form>
    </validation-observer>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BFormGroup, BFormInput,
  BForm, BRow, BCol, BCardText
} from 'bootstrap-vue'
import { required } from '@validations'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import { mapGetters } from "vuex";
import moment from "moment"
export default {
  name: 'MarkDistributionUpdate',
  components: {
    BCardCode,
    BFormGroup,
    BFormInput,
    BButton, BCardText,
    BForm, BRow, BCol,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      moment,
      classes_id: null,
      version_id: null,
      student_group_id: null,
      academic_year_id: null,
      exam_type_id: null,
      classes: [],
      versions: [],
      student_groups: [],
      required,
      dir: false,
      model_mode: 'add',
      selected_row: {},
      //grade_id: null
    }
  },
  methods: {
    markUpdate(id, md) {
      let mk = this.mk_distributions.find(item => item.id === id);
      if (mk) {
        let data = new FormData();
        data.append('total_mark', mk.total_mark);
        data.append('pass_mark', mk.pass_mark);
        data.append('mark_percent_add', mk.mark_percent_add);
        data.append('grade_id', document.getElementById(md.subject_id + '_grade').value);
        apiCall.post(`/mark/distribution/update${mk.id}`, data).then((response) => {
          if (response.data.status === 'success') {
            this.$toaster.success(response.data.message);
          } else this.$toaster.error(response.data.message);
        }).catch((error) => {
          if (error.response.status == 422) {
            Object.keys(error.response.data.errors).map((field) => {
              this.$toaster.error(error.response.data.errors[field][0]);
            })
          } else this.$toaster.error(error.response.data.message);
        });
      } else this.$toaster.error(this.$t('Invalid request'));
    },

    /*method for get all classes*/
    async getClasses() {
      await apiCall.get('/get/active/class/with/v/s/sg/wings').then((response) => {
        this.classes = response.data;
      }).catch(() => {
        this.classes = [];
      })
    },
    jsonDecode(data) {
      try {
        return JSON.parse(data);
      }
      catch (e) {
        return [];
      }
    },
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters(['grade_types', 'academic_years', 'exam_types', 'subjects', 'subjectByID', 'mark_distributions']),
    vs() {
      return this.$store.getters.versions;
    },
    cls() {
      return this.$store.getters.classes;
    },
    stg() {
      return this.$store.getters.student_groups;
    },
    exm_types() {
      return this.$store.getters.exam_types.filter(item => {
        if (parseInt(item.is_active) === 1) {
          return item;
        }
      });
    },
    s_types() {
      return this.$store.getters.subjects.filter(item => {
        if (parseInt(item.classes_id) === this.classes_id && parseInt(item.version_id) === this.version_id
          && parseInt(item.student_group_id) === this.student_group_id) return item;
      });
    },
    filteredsubjectList() {
      const existingList = new Set();
      const markList = new Set();

      this.mk_distributions.forEach(car => {
      if(!existingList.has(car.subject_id)){
        markList.add(car);
        existingList.add(car.subject_id);
      }
      });
      
      return Array.from(markList);
    },
    /*filtered mark distribution*/
    mk_distributions() {
      return this.$store.getters.mark_distributions.filter(item => 
          parseInt(item.exam_type_id) === this.exam_type_id &&
          parseInt(item.academic_year_id) === this.academic_year_id &&
          parseInt(item.classes_id) === this.classes_id 
        );
    }
  },
  created() {
    this.getClasses();
    if (this.grade_types.length < 1) this.$store.dispatch('GET_ALL_GRADE_TYPE');

    if (this.exam_types.length < 1) this.$store.dispatch('GET_ALL_EXAM_TYPE');
    if (this.academic_years.length < 1) this.$store.dispatch('GET_ALL_ACADEMIC_YEAR');
    // if (this.vs.length < 1) this.$store.dispatch('GET_ALL_VERSION');
    // if (this.cls.length < 1) this.$store.dispatch('GET_ALL_CLASSES');
    // if (this.stg.length < 1) this.$store.dispatch('GET_ALL_STUDENT_GROUP');
    if (this.subjects.length < 1) this.$store.dispatch('GET_ALL_SUBJECT');
    this.$store.dispatch('GET_ALL_MARK_DISTRIBUTION');

  },
  watch: {
    classes_id() {
      if (this.classes_id) {
        this.versions = [];
        this.student_groups = [];
        this.version_id = null;
        this.student_group_id = null;
        let cls = this.classes.find(item => parseInt(item.id) === this.classes_id);
        if (cls) {
          this.versions = cls.versions;
          this.student_groups = cls.student_groups;
        }
      } else {
        this.versions = [];
        this.version_id = null;
        this.student_group_id = null;
        this.student_groups = [];
      }
    },
  }
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>